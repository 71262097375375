import request from "@/utils/request";

/**
 *
 * @param {Object} data limit:单页个数，page：页码
 * @returns
 */
export function getDetailList(data) {
    const {
        page = 1,
        limit = 10,
        sort = "createdAt",
        order = "ASE", // 默认排序顺序
    } = data;
    return request({
        url: `/api/detail?page=${page}&limit=${limit}&order=${order}`,
        method: "get",
    });
}

/**
 *
 * @param {Object} data id:文章对应id
 * @returns
 */
export function getDetail(id) {
    return request({
        url: `/api/detail/${id}`,
        method: "get",
    });
}
