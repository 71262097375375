<template>
    <Layout :footer-style="2">
        <!-- 简介区域start -->
        <div
            class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="react-image mb--20" data-aos="fade-up">
                                <img
                                    src="../../assets/images/index/badge-vue-js.png"
                                    alt="Doob Vue" />
                            </div>
                            <h1
                                class="title display-two"
                                data-aos="fade-up"
                                data-aos-delay="100">
                                视惠数聚是专注于 <br />
                                <span class="theme-gradient">数据可视化</span> &
                                <span class="theme-gradient">分析</span>
                                <br />
                                的创新型企业。
                            </h1>
                            <p
                                class="description"
                                data-aos="fade-up"
                                data-aos-delay="150">
                                我们致力于帮助客户解锁数据的潜力，通过可视化工具和高级分析技术提供深入洞察，支持数据驱动的决策。
                            </p>
                            <div
                                class="button-group"
                                data-aos="fade-up"
                                data-aos-delay="200">
                                <a
                                    class="btn-default btn-large round btn-icon"
                                    href="#demo"
                                    >查看案例
                                    <Icon name="arrow-down" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 简介区域end -->

        <Separator />

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div
                            class="thumbnail"
                            data-aos="fade-up"
                            data-aos-delay="250">
                            <img
                                class="w-100"
                                :src="`${bannerData.imgUrl}`"
                                alt="About Images" />
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h2
                                    class="title theme-gradient"
                                    data-aos="fade-up"
                                    data-aos-delay="280">
                                    我们能为您提供 <br />
                                    <vue-typed-js
                                        :strings="[
                                            '数字孪生',
                                            '空间地理信息服务',
                                            '智慧城市治理专业解决方案',
                                            '数据可视化 ^2500',
                                        ]"
                                        :loop="true"
                                        :type-speed="80"
                                        :start-delay="500"
                                        :back-speed="50">
                                        <b
                                            class="is-visible theme-gradient typing"></b>
                                    </vue-typed-js>
                                </h2>
                                <p
                                    class="banner"
                                    data-aos="fade-up"
                                    data-aos-delay="310">
                                    {{ bannerData.description }}
                                </p>

                                <div
                                    class="read-more-btn mt--40"
                                    data-aos="fade-up"
                                    data-aos-delay="370">
                                    <router-link
                                        class="btn-default"
                                        to="/about">
                                        <span>更多信息</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        <Separator />

        <!-- 部分案例展示区域start -->
        <div class="rn-demo-area rn-section-gap" id="demo">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="案例展示"
                            title="部分项目."
                            description="数据可视化：我们提供各种数据可视化工具和技术，帮助客户以直观、交互式的方式呈现和探索数据。我们可以根据客户需求定制可视化解决方案，包括仪表盘、图表、地图等。"
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div
                        class="col-lg-4 col-md-6 col-12 mt--40"
                        v-for="(page, index) in indexPages"
                        :key="index"
                        data-aos="fade-up"
                        :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="`/project-details/${page.id}`">
                                <div class="thumbnail">
                                    <img
                                        class="image-dark"
                                        :src="page.img"
                                        alt="Corporate images" />
                                    <img
                                        class="image-light"
                                        :src="page.img"
                                        alt="Corporate images" />
                                    <!-- <span v-if="page.id" class="label-badge">{{ page.id }}</span> -->
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link
                                    :to="`/project-details/${page.id}`"
                                    >{{ page.title }}</router-link
                                >
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 部分案例展示区域End -->

        <Separator />
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import Icon from "../../components/icon/Icon";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import { getBanner } from "@/api/banner";
import { getDetailList } from "@/api/detail";

// function isTimeInRange() {
//   const currentHour = new Date().getHours();
//   return currentHour >= 18 || currentHour < 6;
// }

// if (!isTimeInRange()) {
//   document.body.classList.add("active-light-mode");
// }

export default {
    name: "Index",
    components: { Icon, Layout, Separator, SectionTitle },
    data() {
        return {
            bannerData: [],
            indexPages: [],
        };
    },
    methods: {
        async bannerInit() {
            const result = await getBanner();
            return result.data.data[0];
        },
        projectInit() {
            getDetailList({ page: 1, limit: 3, order: "DESC" }).then(
                ({ data }) => {
                    this.indexPages = data.data.rows;
                }
            );
        },
    },
    async created() {
        this.projectInit();
        this.bannerData = await this.bannerInit();
    },
};
</script>
/
<style scoped>
.banner {
    text-indent: 2em;
}
</style>
