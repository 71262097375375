import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/vfdata/Index";
import About from "../views/vfdata/About";
import ProductProduction from "../views/vfdata/ProductProduction";
import ProjectListPage from "../views/vfdata/ProjectListPage";
import PlatformIntroduction from "../views/vfdata/PlatformIntroduction";
import ProjectDetailsPage from "../views/vfdata/ProjectDetailsPage";
import ContactPage from "../views/vfdata/ContactPage";
import ErrorPage from "../views/vfdata/404";

import { addUserVisit } from "../api/visit";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        component: Index,
        meta: {
            title: "四川视惠数聚科技有限公司",
        },
    },
    {
        path: "/about",
        name: "About",
        component: About,
        meta: {
            title: "关于我们",
        },
    },
    {
        path: "/product-production",
        name: "Product Production",
        component: ProductProduction,
        meta: {
            title: "产品生态",
        },
    },
    {
        path: "/project-list",
        name: "Project list",
        component: ProjectListPage,
        meta: {
            title: "案例展示",
        },
    },
    // {
    //     path: "/platform-introduction",
    //     name: "Platform Introduction",
    //     component: PlatformIntroduction,
    //     meta: {
    //         title: "平台介绍",
    //     },
    // },
    {
        path: "/project-details/:id",
        name: "Project details",
        component: ProjectDetailsPage,
        meta: {
            title: "项目详情",
        },
    },

    {
        path: "/contact",
        name: "Contact",
        component: ContactPage,
        meta: {
            title: "联系我们",
        },
    },
    {
        path: "*",
        name: "Error",
        component: ErrorPage,
        meta: {
            title: "404 || 四川视惠数聚科技有限公司",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

function recordPageVisit(pageName, userAgent) {
    const body = {
        page: pageName,
        userAgent: userAgent,
        // IP地址将由后端获取
    };
    addUserVisit(body);
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    const pageName =
        to.meta.title === "四川视惠数聚科技有限公司" ? "首页" : to.meta.title; // 获取页面名称
    const userAgent = navigator.userAgent; // 获取UserAgent
    // 调用记录访问的函数
    recordPageVisit(pageName, userAgent);
    next();
    window.scrollTo(0, 0);
});

export default router;
