<template>
    <Layout header-class="header-transparent">
        <!-- 1. start -->
        <div
            class="slider-area slider-style-1 height-850 bg_image"
            data-black-overlay="7"
            :style="{
                'background-image': `url(${require(`@/assets/images/bg/main-logo.jpg`)})`,
            }">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <!-- <div>
                <h3
                  class="rn-sub-badge"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <span class="theme-gradient">四川视惠数聚科技有限公司</span>
                </h3>
              </div> -->
                            <h1
                                class="title display-one"
                                data-aos="fade-up"
                                data-aos-delay="150">
                                帮助组织或个人从海量数据中获得有意义的见解，
                                <br />
                                促进智能决策和业务创新。
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 1. End -->

        <!-- 2. start -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title text-lg-end">专业团队</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            我们拥有经验丰富的数据科学家和可视化专家团队，具备处理各种复杂数据和业务场景的能力。
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title text-lg-end">创新技术</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            我们跟踪最新的数据可视化和分析技术，不断提升我们的解决方案和工具，以满足客户的需求。
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title text-lg-end">定制化解决方案</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            我们根据客户的具体需求和挑战，提供个性化的数据可视化和分析解决方案，确保最大化的价值和效益。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 2. End -->

        <!-- 3. Start -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mt--10">
            <Brand :brand-list="brandList" :brand-style="3" />
          </div>
        </div>
      </div>
    </div> -->
        <!-- 3. End -->

        <Separator />

        <!-- 4. Start -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="我们能为你做什么"
                            title="为您提供服务"
                            description="无论您是一个小型创业公司还是一个大型企业，数据孪生公司都愿意成为您的合作伙伴，帮助您利用数据驱动增长和创新。请随时联系我们"
                            data-aos="fade-up" />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center" />
            </div>
        </div>
        <!-- 4.TimeLine Start -->

        <!-- <div class="rwt-timeline-area rn-section-gapBottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" :timeline-style="3" />
          </div>
        </div>
      </div>
    </div> -->

        <!-- 4.TimeLine End -->

        <!-- <AboutFour :image="require(`@/assets/images/about/contact-image.jpg`)" /> -->

        <!-- Start Elements Area -->
        <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12" />
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator />

        <!-- Start Elements Area -->
        <!-- <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们的企业团队"
              title="企业团队成员"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
            v-for="(teamMember, index) in teamData"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="4" />
          </div>
        </div>
      </div>
    </div> -->
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../../components/elements/about/AboutFour";
import ServiceOne from "../../components/elements/service/ServiceOne";
import Timeline from "../../components/elements/timeline/Timeline";
import Counter from "../../components/elements/counterUp/Counter";
import Brand from "../../components/elements/brand/Brand";
import Team from "../../components/elements/team/Team";

export default {
    name: "About",
    components: {
        Team,
        Brand,
        Counter,
        Timeline,
        ServiceOne,
        AboutFour,
        SectionTitle,
        Separator,
        Layout,
    },
    data() {
        return {
            brandList: [
                {
                    image: require(`@/assets/images/brand/brand-01.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-02.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-03.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-04.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-05.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-06.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-07.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-08.png`),
                },
                {
                    image: require(`@/assets/images/brand/brand-01.png`),
                },
            ],
            timelineData: [
                {
                    id: "1",
                    title: "Knowledge",
                    description:
                        "Present all available features in Essentials.",
                },
                {
                    id: "2",
                    title: "Working",
                    description:
                        "All Feature available features in Essentials.",
                },
                {
                    id: "3",
                    title: "Solution",
                    description:
                        "Popular Feature available features in Essentials.",
                },
                {
                    id: "4",
                    title: "Process",
                    description:
                        "Latest Feature available features in Essentials.",
                },
            ],
            counterData: [
                {
                    number: 199,
                    title: "参与项目",
                },
                {
                    number: 575,
                    title: "用户数量",
                },
                {
                    number: 691252,
                    title: "创造价值",
                },
                {
                    number: 500,
                    title: "持续时间",
                },
            ],
            // teamData: [
            //   {
            //     image: "team-dark-01",
            //     name: "江夏",
            //     designation: "总经理",
            //     location: "四川 成都",
            //     description:
            //       "Yes, I am a product designer. I have a passion for product design.",
            //   },
            //   {
            //     image: "team-dark-02",
            //     name: "王怡君",
            //     designation: "办公室主任",
            //     location: "四川 成都",
            //     description:
            //       "Yes, I am a product designer. I have a passion for product design.",
            //   },
            //   {
            //     image: "team-dark-02",
            //     name: "赵茂希",
            //     designation: "技术总监",
            //     location: "四川 成都",
            //     description:
            //       "负责制定技术规划和战略，指导技术团队，协助解决复杂的技术问题，确保公司的技术架构和解决方案的高质量和可靠性。",
            //   },
            //   {
            //     image: "team-dark-04",
            //     name: "杨毅",
            //     designation: "开发经理",
            //     location: "陕西 西安",
            //     description:
            //       "领导和管理开发团队，负责项目规划和执行，协调开发流程，确保项目按时交付，并保证开发团队的高效协作和技术质量。",
            //   },
            //   {
            //     image: "team-dark-04",
            //     name: "黎亮",
            //     designation: "开发经理",
            //     location: "四川 成都",
            //     description:
            //       "项目总监是负责领导和管理公司项目部门的高级管理者。他们在项目的规划、执行和交付过程中扮演关键角色，确保项目的成功完成并符合客户的期望。",
            //   },
            // ],
        };
    },
};
</script>
