<template>
    <Layout>
        <div class="row" data-aos="fade-up">
            <div class="col-lg-12 mt--80">
                <h1
                    class="title display-one text-center theme-gradient"
                    data-aos="fade-up"
                    data-aos-delay="150">
                    VFdata低代码平台介绍
                </h1>
            </div>
        </div>

        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div
                    class="row mb--120"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="col-lg-12">
                        <div
                            class="d-flex flex-column flex-xl-row justify-content-xl-between mb--60">
                            <h2
                                class="title"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                简单、易上手的开发模式
                            </h2>
                            <h4
                                class="subtitle d-flex align-items-lg-end"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                通过拖拽的方式即可轻松完成数据看板的开发。
                            </h4>
                        </div>

                        <img class="w-100 gif" src="@/assets/gifs/1.gif" />
                    </div>
                </div>
                <Separator />
                <div
                    class="row rn-section-gap"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="col-lg-12">
                        <div
                            class="d-flex flex-column flex-xl-row justify-content-xl-between mb--60">
                            <h2
                                class="title"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                灵活的数据绑定
                            </h2>
                            <h4
                                class="subtitle d-flex align-items-lg-end"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                任何组件都支持多种数据接入方式，不管是静态数据还是数据库、亦或者是接口数据都能够灵活的对图表赋能。
                            </h4>
                        </div>
                        <img class="w-100 gif" src="@/assets/gifs/2.gif" />
                    </div>
                </div>
                <Separator />
                <div
                    class="row rn-section-gap"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="col-lg-12">
                        <div
                            class="d-flex flex-column flex-xl-row justify-content-xl-between mb--60">
                            <h2
                                class="title"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                随时预览灵活调整
                            </h2>
                            <h4
                                class="subtitle d-flex align-items-lg-end"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                开发过程中随时预览，即使不同的屏幕分辨率都能完全自适应，真正做到数据看板的所见即所得。
                            </h4>
                        </div>
                        <img class="w-100 gif" src="@/assets/gifs/3.gif" />
                    </div>
                </div>
                <Separator />
                <div
                    class="row rn-section-gap"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="col-lg-12">
                        <div
                            class="d-flex flex-column flex-xl-row justify-content-xl-between mb--60">
                            <h2
                                class="title"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                图表组件太少？
                            </h2>
                            <h4
                                class="subtitle d-flex align-items-lg-end"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                图表组件随时更新/添加，满足不同展示需求。
                            </h4>
                        </div>
                        <img class="w-100 gif" src="@/assets/gifs/4.gif" />
                    </div>
                </div>
                <Separator />
                <div
                    class="row rn-section-gap"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="col-lg-12">
                        <div
                            class="d-flex flex-column flex-xl-row justify-content-xl-between mb--60">
                            <h2
                                class="title"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                丰富的事件属性
                            </h2>
                            <h4
                                class="subtitle d-flex align-items-lg-end"
                                data-aos="fade-up"
                                data-aos-delay="60">
                                几乎所有组件都支持事件绑定，使业务流程编辑变得简洁且高效。
                            </h4>
                        </div>
                        <img class="w-100 gif" src="@/assets/gifs/5.gif" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Icon from "@/components/icon/Icon";
import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
import Separator from "@/components/elements/separator/Separator";
import BlogPost from "@/components/blog/BlogPost";
import Layout from "@/components/common/Layout";
import Breadcrumb from "@/components/elements/breadcrumb/Breadcrumb";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { getDetailList } from "@/api/detail";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "ProjectListPage",
    components: { Icon, BlogPost, Layout, Breadcrumb, SectionTitle, Separator },
    data() {
        return {
            data: {
                rows: [],
            },
            initData: {
                page: 1,
                limit: 6,
            },
        };
    },
    methods: {
        async fetchData() {
            const resp = await getDetailList(this.initData);
            if (resp.data.data.total > this.data.rows.length) {
                this.data.rows = [...this.data.rows, ...resp.data.data.rows];
                this.initData.page += 1;
            } else {
                // 如果没有更多数据，停止观察
                console.log("结束");
                if (this.observer) {
                    this.observer.disconnect();
                }
                return;
            }

            this.$nextTick(() => {
                this.createObserver();
            });
        },

        createObserver() {
            if (this.observer) {
                this.observer.disconnect(); // 断开旧的观察者实例
            }
            const observerOptions = {
                root: null,
                rootMargin: "20px",
                threshold: 0.1,
            };
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const target = entry.target;
                        this.fetchData();
                        this.observer.unobserve(target);
                    }
                });
            }, observerOptions);

            const blogs = document.querySelectorAll(".blog-item");
            const lastItem = blogs[blogs.length - 1]; // 获取最后一个元素
            if (lastItem) {
                this.observer.observe(lastItem);
            }
        },
    },

    created() {
        this.fetchData();
        setTimeout(() => {
            const images = gsap.utils.toArray(".gif");

            images.forEach((img) => {
                gsap.fromTo(
                    img,
                    {
                        borderRadius: 60,
                    },
                    {
                        borderRadius: 0,
                        scrollTrigger: {
                            trigger: img, // 每个图片元素作为触发器
                            start: "top bottom", // 当图片顶部到达视口底部时开始
                            end: "top center", // 当图片顶部到达视口中间时结束
                            scrub: true, // 滚动时同步动画
                        },
                    }
                );
            });
        });
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
};
</script>

<style lang="scss" scoped>
.observer-element {
    width: 50px;
    height: 50px;
    background: red;
}
</style>
