<template>
    <ul class="mainmenu" id="test">
        <li class="has-droupdown has-menu-child-item">
            <router-link to="/" :class="{ active: $route.path === '/' }" exact>
                首页
            </router-link>
        </li>

        <li class="has-droupdown has-menu-child-item">
            <router-link
                to="/product-production"
                :class="{ active: $route.path === '/product-production' }">
                产品生态
            </router-link>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <router-link
                to="/project-list"
                :class="{ active: $route.path === '/project-list' }">
                案例展示
            </router-link>
        </li>
        <!-- <li>
            <router-link
                to="/platform-introduction"
                :class="{ active: $route.path === '/platform-introduction' }"
                >平台介绍</router-link
            >
        </li> -->
        <li>
            <router-link
                to="/contact"
                :class="{ active: $route.path === '/contact' }"
                >联系方式</router-link
            >
        </li>
        <li>
            <router-link
                to="/about"
                :class="{ active: $route.path === '/about' }"
                >关于我们</router-link
            >
        </li>
    </ul>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
    name: "Nav",
    components: { Icon },
};
</script>

<style lang="scss" scoped>
.router-link-active {
    color: #36c6f0;
}
.active-light-mode #test .router-link-active {
    color: #36c6f0;
}
</style>
