import request from "@/utils/request";

export function submitContactInfo(data) {
  return request({
    url: "/api/contact",
    method: "post",
    data,
  });
}

export function rebotMessagePush(data) {
  return request({
    url: "https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=4cc9df56-5f14-4074-860b-32af12122c40",
    method: "post",
    data,
  });
}
