import axios from "axios";

const request = axios.create({
    // baseURL: "http://localhost:3001",
    timeout: 5000,
});

//设置请求拦截器
request.interceptors.request.use((config) => {
    return config;
});

//设置返回拦截器
request.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

export default request;
