<template>
  <div class="footer-style-2">
    <CallToAction
      v-if="data.showFooter"
      :style-type="8"
      title="我们期待与您建立联系并共同探索数字孪生"
      subtitle="无论公司&个人我们都将是您的最佳选择"
      btn-text="联系我们"
    />

    <CopyrightTwo v-if="data.showCopyright" />
    <ScrollTop />
  </div>
</template>

<script>
import CopyrightTwo from "./CopyrightTwo";
import CallToAction from "../../elements/callToAction/CallToAction";
import ScrollTop from "./ScrollTop";

export default {
  name: "FooterTwo",
  components: { CallToAction, CopyrightTwo, ScrollTop },
  props: {
    data: {
      default: null,
    },
  },
};
</script>
