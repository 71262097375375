<template>
    <Layout :show-newsletter="false" :show-cta="false">
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row" data-aos="fade-up">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            subtitle="四川视惠数聚科技有限公司"
                            title="联系表单"
                            :fontSize="32" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12" data-aos="fade-up">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon
                                                name="headphones"
                                                :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">联系电话</h4>
                                            <p>
                                                <a href="tel:+86 135 404 06634"
                                                    >+86 1354 0406 634</a
                                                >
                                            </p>
                                            <!-- <p><a href="tel:+86 ">+86 </a></p> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">
                                                我们的电子邮件
                                            </h4>
                                            <p>
                                                <a
                                                    href="mailto:wangyin@vfdata.cn"
                                                    >vfdata@vfdata.cn</a
                                                >
                                            </p>
                                            <!-- <p>
                        <a href="mailto:example@gmail.com">example@gmail.com</a>
                      </p> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">我们的位置</h4>
                                            <p>
                                                四川省成都市武侯区武兴四路166号
                                                <br />
                                                西部智谷6幢6层B单元404
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15" data-aos="fade-up">
                    <div class="col-lg-7">
                        <ContactForm />
                    </div>
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import Breadcrumb from "../../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import Icon from "../../components/icon/Icon";
import GoogleMap from "../../components/elements/contact/GoogleMap";
import ContactForm from "../../components/elements/contact/ContactForm";

export default {
    name: "ContactPage",
    components: {
        ContactForm,
        GoogleMap,
        Icon,
        SectionTitle,
        Breadcrumb,
        Layout,
    },
};
</script>
