<template>
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2" data-aos="fade-up">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1
                                        class="theme-gradient"
                                        v-html="data.title" />
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20" />
                                        <router-link to="#">{{
                                            data.author
                                        }}</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20" />
                                        {{ data.createdAt }}
                                    </li>
                                </ul>
                                <div class="thumbnail alignwide mt--60">
                                    <img
                                        class="w-100 radius"
                                        :src="data.img"
                                        :alt="data.title" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12" data-aos="fade-up">
                            <div
                                class="content content-text"
                                v-html="data.htmlContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/common/Layout";
import Breadcrumb from "@/components/elements/breadcrumb/Breadcrumb";
import Icon from "@/components/icon/Icon";
import BlogPostMixin from "@/mixins/BlogPostMixin";
import { getDetail } from "@/api/detail.js";
import formatDate from "@/utils/formatDate.js";

export default {
    name: "ProjectDetailsPage",
    components: { Icon, Layout, Breadcrumb },
    mixins: [BlogPostMixin],
    data() {
        return {
            id: this.$route.params.id,
            data: {},
        };
    },

    methods: {
        async fetchData() {
            const resp = await getDetail(this.id);
            return resp;
        },
    },
    async created() {
        const result = await this.fetchData();
        this.data = result.data.data;
        this.data.createdAt = formatDate(this.data.createdAt);
    },
};
</script>

<style scoped>
.content-text {
    text-indent: 2em;
}
</style>
