import request from "@/utils/request";

export function addUserVisit(data) {
    return request({
        url: "/api/visit",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data,
    });
}
