<template>
    <Layout>
        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <h2>产品生态</h2>
                            <Separator />
                            <div class="content" v-html="post.body" />
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 text-center">
            <div class="rwt-load-more text-center mt--60">
              <a class="btn-default" href="#"> View More Post <Icon name="loader" /> </a>
            </div>
          </div> -->
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import Breadcrumb from "../../components/elements/breadcrumb/Breadcrumb";
import BlogPost from "../../components/blog/BlogPost";
import Icon from "../../components/icon/Icon";
import BlogPostMixin from "../../mixins/BlogPostMixin";
import Separator from "../../components/elements/separator/Separator";

export default {
    name: "BlogGridPage",
    components: { Icon, BlogPost, Layout, Breadcrumb, Separator },
    mixins: [BlogPostMixin],
    data() {
        return {
            post: {
                body: `<p>我们公司以构建全面而强大的产品生态为目标，为客户提供综合解决方案，以满足其不断增长的需求。我们的产品生态涵盖了多个关键领域，包括但不限于以下方面：</p>
          <p><b>数字孪生平台</b>：我们提供先进的数字孪生平台，帮助客户实现虚拟模拟和仿真，以提升生产效率、优化运营流程和进行智能决策。我们的数字孪生平台提供强大的数据管理、模型构建和可视化功能，使客户能够更好地理解和管理其现实世界的资产和过程。</p>
          <p><b>空间地理信息服务</b>：我们的空间地理信息服务涵盖了地图制作、地理数据分析、位置智能和地理信息系统（GIS）等方面。通过深入挖掘地理数据的潜力，我们帮助客户获得有关位置、空间关系和地理趋势的深入洞察，以支持决策制定、资源规划和地理可视化。</p>
          <p><b>智慧城市解决方案</b>：我们提供针对智慧城市治理的定制化解决方案，涵盖了交通管理、智能安防、能源管理、环境监测和市政设施等领域。通过整合数字孪生和空间地理信息服务，我们帮助城市实现可持续发展、提升居民生活质量，并构建智慧、高效的城市管理体系。</p>
          <p><b>交通行业解决方案</b>：我们通过数字孪生、地理信息和智能技术，为交通行业提供全面的解决方案。我们的产品涵盖交通管理、智能交通系统、交通规划和优化、车辆监控和调度等方面。通过实时数据收集、分析和预测，我们帮助交通部门实现交通流量优化、减少拥堵、提升交通安全和提供更便捷的出行体验。</p>
          <p><b>政府行业解决方案</b>：我们为政府部门提供定制化的数字化解决方案，涵盖城市规划、土地管理、资源调配和公共服务等方面。我们的解决方案利用数字孪生和地理信息技术，帮助政府实现数据驱动的决策制定、优化公共资源配置、提升公共服务效率，并促进城市的可持续发展和社会福祉。</p>
          <p><b>其他行业解决方案</b>：除了交通和政府行业，我们的产品生态还扩展到其他行业，如能源、制造、物流等。我们根据行业需求和挑战，提供针对性的解决方案，包括设备监控与管理、供应链优化、生产过程改进、环境监测和资源利用效率等方面。通过数字化技术和数据分析，我们帮助企业提升效率、降低成本、提高可持续性，并推动行业的创新和发展。</p>
          <p>我们致力于通过产品创新和技术领先，为交通行业、政府部门以及其他行业提供高效、智能的解决方案。我们的目标是帮助客户实现数字化转型，应对行业挑战，提升竞争力，并共同打造一个更智慧、更可持续的未来。</p>
          `,
            },
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
h2 {
    text-align: center;
}
.content {
    margin-top: 2em;
    text-indent: 2em; /* Adjust the value as needed */
}
</style>
