<template>
    <div class="copyright-area copyright-style-one variation-two">
        <div class="container">
            <div class="row align-items-center">
                <!-- <div class="col-lg-4 col-md-7 col-sm-12 col-12">
          <div class="copyright-left">
            <ul class="ft-menu link-hover">
              <li><router-link to="#">Privacy Policy</router-link></li>
              <li><router-link to="#">Terms And Condition</router-link></li>
              <li><router-link to="/contact">Contact Us</router-link></li>
            </ul>
          </div>
        </div> -->
                <div class="mt_sm--20">
                    <div class="copyright-center text-center">
                        <!-- <ul class="social-icon social-default color-lessdark justify-content-center">
              <li>
                <a href="https://www/facebook.com/" target="_blank"><Icon name="facebook" /></a>
              </li>
              <li>
                <a href="https://www/twitter.com/" target="_blank"><Icon name="twitter" /></a>
              </li>
              <li>
                <a href="https://www/instagram.com/" target="_blank"><Icon name="instagram" /></a>
              </li>
              <li>
                <a href="https://www/linkedin.com/" target="_blank"><Icon name="linkedin" /></a>
              </li>
            </ul> -->
                        <div class="copyright-right text-center">
                            <p class="copyright-text">
                                ©
                                {{ getFullYear() }}
                                {{ companyInfo.companyName
                                }}<span class="filings" @click="clickHandler">{{
                                    companyInfo.recordInfo
                                }}</span>
                                公司地址：{{ companyInfo.companyAddress }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "../../icon/Icon";
import { getCompanyInfo } from "../../../api/company";
import { getFullYear } from "@/utils/formatDate";

export default {
    name: "CopyrightTwo",
    components: { Icon },
    data() {
        return {
            companyInfo: {
                companyAddress: "",
                companyName: "",
                recordInfo: "",
            },
        };
    },
    methods: {
        async fetchData() {
            const result = await getCompanyInfo();
            this.companyInfo = result.data.data;
            return result;
        },
        clickHandler() {
            window.open(
                "https://beian.miit.gov.cn/#/Integrated/index",
                "_blank"
            );
        },
        getFullYear,
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.filings {
    margin: 0 1em 0;
    cursor: pointer;
}
.filings:hover {
    color: rgb(64, 169, 255);
}
</style>
