<template>
  <form class="contact-form-1" action="" ref="form">
    <div class="form-group">
      <input
        type="text"
        name="name"
        placeholder="您的姓名"
        @blur="validateField('name', validateUsername)"
        v-model="form.name"
      />
      <p class="err">{{ error.name }}</p>
    </div>

    <div class="form-group">
      <input
        type="email"
        name="email"
        placeholder="电子邮箱地址"
        required
        @blur="validateField('email', validateEmail)"
        v-model="form.email"
      />
      <p class="err">{{ error.email }}</p>
    </div>

    <div class="form-group">
      <input
        type="text"
        name="telephone"
        placeholder="手机号码"
        required
        @blur="validateField('telephone', validatePhoneNumber)"
        v-model="form.telephone"
      />
      <p class="err">{{ error.telephone }}</p>
    </div>

    <div class="form-group">
      <input type="text" name="theme" placeholder="主题" v-model="form.theme" />
      <p class="err"></p>
    </div>

    <div class="form-group">
      <textarea name="content" placeholder="详细信息" required v-model="form.content"> </textarea>
      <p class="err"></p>
    </div>

    <div class="form-group">
      <button class="btn-default btn-large" @click.prevent="handleSubmit">现在提交</button>
    </div>

    <div class="form-group" v-html="showResult ? result : null" />
  </form>
</template>

<script>
import { submitContactInfo } from "@/api/contact";
export default {
  name: "ContactForm",
  data() {
    return {
      showResult: false,
      form: {
        name: "",
        email: "",
        telephone: "",
        theme: "",
        content: "",
      },
      error: {
        name: "",
        email: "",
        telephone: "",
        theme: "",
        content: "",
      },
      result: "",
    };
  },
  computed: {
    isFormInvalid() {
      return Object.values(this.error).some((errorMessage) => errorMessage !== "");
    },
  },
  methods: {
    async handleSubmit() {
      // 手动验证所有表单项
      this.validateField("name", this.validateUsername);
      this.validateField("email", this.validateEmail);
      this.validateField("telephone", this.validatePhoneNumber);
      // 在这里添加更多表单字段的验证，如果有的话

      if (!this.isFormInvalid) {
        // 注意这里改成了 !this.isFormInvalid
        // 如果表单验证通过，则执行提交逻辑
        const result = await submitContactInfo([this.form]);
        if (!result.data.code) {
          //说明成功推送到数据库，则给机器人推送消息到企业微信
          const data = result.data.data[0];
          //清空表单和错误消息
          this.form = {
            name: "",
            email: "",
            telephone: "",
            theme: "",
            content: "",
          };
          this.error = {
            name: "",
            email: "",
            telephone: "",
            theme: "",
            content: "",
          };
          //设置文字提示
          this.changeShow("您的邮件已成功发送。我们很快就会联系您。", 1500);
        } else {
          //说明提交次数超过两次发出文字提示
          this.changeShow("提交太过频繁，请15分钟重试", 1500);
        }
      } else {
        // 如果表单验证未通过，则显示错误信息
        this.changeShow("表格填写有误，请检查。", 1500);
      }
    },
    //显示隐藏提示方法
    changeShow(value, time) {
      this.result = `<p class="success-message">${value}</p>`;
      this.showResult = true;
      setTimeout(() => {
        this.showResult = false;
      }, time);
    },
    //验证函数,传入对应的数据，和验证程序。
    validateField(fieldName, validator) {
      const value = this.form[fieldName];
      const errorMessage = validator(value);
    },
    //用户名验证
    validateUsername(value) {
      let err = "";
      if (value === "") {
        err = "姓名还未填写";
      } else if (value.length < 2) {
        err = "姓名长度应至少为2个字符";
      } else {
        err = "";
      }
      this.error.name = err;
      return err;
    },
    //邮箱验证
    validateEmail(value) {
      let err = "";
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value === "") {
        err = "邮箱还未填写";
      } else if (!emailRegex.test(value)) {
        err = "邮箱格式存在问题，请重新填写";
      } else {
        err = "";
      }
      this.error.email = err;
      return err;
    },
    //手机号验证
    validatePhoneNumber(value) {
      const phoneRegex = /^1[3456789]\d{9}$/;
      console.log(value);
      let err = "";
      if (value === "") {
        err = "电话号码还未填写";
      } else if (!phoneRegex.test(value)) {
        err = "电话号码格式不正确";
      }
      this.error.telephone = err;
      return err;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  .err {
    position: absolute;
    top: 100%;
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}
</style>
