<template>
  <div class="row row--15 service-wrapper">
    <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key="index">
      <div :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up" data-aos-delay="70">
        <div class="icon">
          <Icon :name="service.icon" :class="`size-${iconSize}`" />
        </div>
        <div class="content">
          <h4 class="title w-600">
            <router-link to="/service-details" v-html="service.title" />
          </h4>
          <p class="description b1 color-gray mb--0" v-html="service.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";

export default {
  name: "ServiceOne",
  components: { Icon },
  props: {
    serviceStyle: {
      type: String,
    },
    iconSize: {
      type: String | Number,
      default: 62,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      serviceList: [
        {
          icon: "activity",
          title: "规划目标",
          description:
            "视惠数聚（VFDATA）致力于成为数字孪生和空间地理信息服务领域的领先企业，为智慧城市治理和金融行业提供专业解决方案和服务。",
        },
        {
          icon: "cast",
          title: "公司展望",
          description:
            "视惠数聚（VFDATA）的展望是成为数字化转型领域的行业领导者，并在智慧城市治理和金融行业中取得广泛认可。",
        },
        {
          icon: "map",
          title: "公司精神",
          description: `1、创新与追求卓越<br />
            2、客户为中心<br />
            3、团队合作与合作共赢
            `,
        },
      ],
    };
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === "left") return "text-start";
      else if (this.textAlign === "center") return "text-center";
      else if (this.textAlign === "end") return "text-end";
      else return "";
    },
  },
};
</script>
