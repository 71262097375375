<template>
  <div class="rn-card box-card-style-default" :class="{ 'card-list-view': blogPostStyle === 'list' }">
    <div class="inner">
      <div class="thumbnail">
        <router-link class="image" :to="`/project-details/${blog.id}`">
          <img class="w-100" :src="blog.img" :alt="blog.title" />
        </router-link>
      </div>
      <div class="content">
        <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <li class="separator">/</li>
          <li>{{ blog.createdAt }}</li>
        </ul>
        <h4 class="title">
          <router-link :to="`/project-details/${blog.id}`">
            {{ blog.title }}
          </router-link>
        </h4>
        <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <li class="separator">/</li>
          <li>{{ this.formatDate(blog.createdAt) }}</li>
        </ul>
        <p v-if="blogPostStyle === 'list'" class="descriptiion">
          {{ blog.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";
export default {
  name: "BlogPost",
  props: {
    blog: {},
    blogPostStyle: {
      type: String,
      default: "card",
    },
  },
  methods: {
    formatDate,
  },
};
</script>
