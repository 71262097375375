import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/scss/bootstrap.scss";
import "animate.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "./assets/scss/style.scss";
import "bootstrap";
import CoolLightBox from "vue-cool-lightbox";
import VueScrollActive from "vue-scrollactive";
import AOS from "aos";
import "aos/dist/aos.css";
import VueTypedJs from "vue-typed-js";

Vue.config.productionTip = false;
Vue.use(CoolLightBox);
Vue.use(VueScrollActive);
Vue.use(VueTypedJs);

new Vue({
  router,
  created() {
    AOS.init({
      duration: 500,
      once: true,
    });
  },
  render: (h) => h(App),
}).$mount("#app");
