<template>
  <!-- Start Google MAp -->
  <div class="google-map-style-1">
    <iframe
      width="100%"
      height="500px"
      frameborder="0"
      src="https://api.map.baidu.com/staticimage/v2?ak=Du3wjvPqe8bNGUde8l4YkAyWPpiwG17P&center=103.984,30.639&width=500&height=500&zoom=19"
    ></iframe>
  </div>
  <!-- End Google MAp -->
</template>

<script>
export default {
  name: "GoogleMap",
};
</script>
