import AppFunctions from "../helpers/AppFunctions";

export default {
  data() {
    return {
      posts: [
        {
          id: "1",
          img: require(`@/assets/images/project/a1.png`),
          author: "视惠数聚",
          authorId: "1",
          view: "70 views",
          publishedAt: "2022-07-22",
          title: "数字孪生场站",
          excerpt: "将实际场站的运营状态和行为精确地模拟和呈现出来的虚拟环境。",
          body: `<p>数字孪生场站是指通过数字化技术和先进的仿真模型，将实际场站的运营状态和行为精确地模拟和呈现出来的虚拟环境。它是数字孪生技术在工业领域的应用之一，旨在提升场站运营效率、优化设备管理和改善工作流程。</p>
          
          <p>数字孪生场站通过将现实场站的各个方面进行建模和仿真，实现了对整个运营过程的全面监控和管理。它包括场站的物理结构、设备设施、工艺流程、能源消耗以及人员行为等多个方面的模拟。通过实时数据采集、传感器监测和智能算法分析，数字孪生场站能够准确地反映场站的实际状态，并提供可视化的信息展示和决策支持。</p>
          
          <p>数字孪生场站的应用带来了许多好处。首先，它可以帮助场站运营人员更好地了解和掌握场站的运行情况，及时发现问题和异常，进行预测和预防性维护，以提高设备的可靠性和稳定性。其次，数字孪生场站能够优化工艺流程和资源配置，提升生产效率和能源利用效率，降低成本并减少浪费。此外，数字孪生场站还可以用于培训和演练，提供虚拟的环境和情景模拟，以帮助员工熟悉工作流程、提升技能和应对突发事件。</p>
          
          <p>总之，数字孪生场站是现代工业领域的一项重要创新技术，通过将虚拟和现实世界相结合，实现对场站运营的精确模拟和优化管理。它为场站运营者提供了更全面、准确的数据和信息，帮助他们做出更明智的决策，并推动工业生产的数字化转型和智能化升级。</p>
          `,

          categories: ["Development", "Company", "Marketing"],
          tags: ["Corporate", "Agency", "Creative", "Design"],
        },
        {
          id: "2",
          img: require(`@/assets/images/project/a3.png`),
          author: "视惠数聚",
          authorId: "2",
          view: "70 views",
          publishedAt: "2023-01-05",
          title: "数字孪生工厂",
          excerpt:
            "将数字孪生技术应用于制造业，建立一个虚拟的工厂模型，实现对生产过程的精确模拟和管理。",
          body: `<p>数字孪生工厂是一种基于数字化技术的虚拟仿真环境，它模拟和复制了真实工厂的物理、工艺和运营过程。通过将物理工厂与虚拟模型相结合，数字孪生工厂提供了一种全面的、实时的工厂管理和优化方案。</p>

          <p>数字孪生工厂的主要目标是实现以下几个方面的效益：</p>
          
          <p>优化生产过程：数字孪生工厂可以对生产过程进行模拟和优化，通过数据分析和算法优化，提高生产效率、降低能源消耗、减少废品率，并提升产品质量。</p>
          
          <p>实时监控和预测：数字孪生工厂通过与实际工厂的实时连接，可以获取实时的传感器数据、设备状态和生产指标。基于这些数据，数字孪生工厂可以进行实时监控和预测，及时发现并解决潜在问题，提高工厂的运营可靠性和稳定性。</p>
          
          <p>节约成本：数字孪生工厂可以通过模拟和优化工厂的运营过程，提供成本优化方案，例如最优的生产调度、库存管理和设备维护计划，帮助企业降低运营成本并提高利润。</p>
          
          <p>虚拟测试和验证：数字孪生工厂可以用于虚拟测试和验证新的生产工艺、设备布局和产品设计。在实际投入生产之前，可以通过数字孪生工厂进行仿真和优化，降低风险和成本。</p>
          
          <p>培训和人员发展：数字孪生工厂可以用于培训和人员发展，通过虚拟仿真环境提供真实的操作和决策体验，帮助员工提高技能和专业知识，并减少培训成本和时间。</p>
          
          <p>数字孪生工厂是工业4.0时代的重要组成部分，它利用先进的技术和数据分析，实现了工厂的智能化、高效化和可持续发展。通过数字孪生工厂，企业可以实现生产过程的优化和创新，提高竞争力，并为未来工业发展奠定坚实基础。</p>`,
          categories: ["UX Design", "Company"],
          tags: ["Minimal", "Company", "Development", "Design"],
        },
        {
          id: "3",
          img: require(`@/assets/images/project/a2.jpg`),
          author: "视惠数聚",
          authorId: "3",
          view: "70 views",
          publishedAt: "2023-05-13",
          title: "智慧社区",
          excerpt:
            "利用数字技术和物联网技术，构建一个智能化、高效的社区管理系统。",
          body: `<p>智慧社区是基于物联网和数字化技术的综合管理系统，旨在提升社区居民的生活品质、安全性和便利性。通过集成各种智能设备、传感器和数据分析技术，智慧社区实现了对社区内各项服务和资源的智能化管理和优化。</p>

          <p>智慧社区项目的主要目标是实现以下几个方面的效益：</p>
          
          <p>安全与监控：智慧社区通过安装视频监控、门禁系统和智能报警设备等，实现对社区内部和周边环境的实时监控。通过数据分析和智能算法，可以及时发现异常情况，如入侵、火灾等，并采取相应的措施保障社区的安全。</p>
          
          <p>设施与资源管理：智慧社区通过物联网技术和传感器设备，对社区内的公共设施（如照明、供水、供电等）进行实时监测和管理。通过智能控制和优化调度，可以提高设施的效率和节能性，同时提供居民便利的服务。</p>
          
          <p>社区服务与交互：智慧社区提供便捷的居民服务平台，包括在线报修、社区活动通知、物业费用管理等功能。居民可以通过手机App或者智能终端与社区管理进行互动，提出需求、获取信息和参与社区活动，增进社区内部的互动和交流。</p>
          
          <p>环境保护与节能减排：智慧社区通过智能化管理和数据分析，对社区的能源消耗、垃圾处理和环境质量进行监测和控制。通过优化能源利用和提高垃圾处理效率，可以减少资源浪费和环境污染，实现可持续发展的目标。</p>
          
          <p>社区治理与信息化：智慧社区提供信息化的社区治理平台，支持社区管理者进行数据分析、决策和规划。通过对居民信息、社区资源和服务数据的整合和分析，可以优化社区的运营和管理，提高居民的满意度和参与度。</p>
          
          <p>智慧社区项目的实施可以为居民提供更加安全、便捷和舒适的居住环境，同时提升社区的管理效率和可持续发展能力。通过数字化和智能化的手段，智慧社区为现代城市的社区建设和社区治理带来了新的发展机遇。</p>`,
          categories: ["Development", "UX Design", "Business", "Company"],
          tags: ["App Landing", "Startup", "Development", "Design"],
        },
        {
          id: "4",
          img: require(`@/assets/images/blog/blog-04.jpg`),
          author: "Jannin",
          authorId: "4",
          view: "70 views",
          publishedAt: "25 Aug 2022",
          title: "Top 5 Lessons About Corporate To Learn Before.",
          excerpt: "We have always known the power of digital we've.",
          body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
          categories: ["Marketing", "UX Design", "Business"],
          tags: ["App", "Business", "Software", "Design"],
        },
        {
          id: "5",
          img: require(`@/assets/images/blog/blog-05.jpg`),
          author: "Jannin",
          authorId: "5",
          view: "70 views",
          publishedAt: "23 Jul 2022",
          title: "Master The Art Of Corporate With These 5 Tips.",
          excerpt: "We have always known the power of digital we've.",
          body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
          categories: ["App Development", "UX Design", "Application", "Art"],
          tags: ["Company", "Corporate", "Development", "Design"],
        },
        {
          id: "6",
          img: require(`@/assets/images/blog/blog-06.jpg`),
          author: "John Dou",
          authorId: "6",
          view: "70 views",
          publishedAt: "30 Jun 2022",
          title: "Corporate Is Your Worst Enemy. 5 Ways To Defeat It.",
          excerpt: "We have always known the power of digital we've.",
          body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
          categories: ["Application", "UX Design", "Art"],
          tags: ["App", "Landing", "Design", "Software"],
        },
        {
          id: "7",
          img: require(`@/assets/images/blog/blog-02.jpg`),
          author: "Fatima Asrafy",
          authorId: "2",
          view: "70 views",
          publishedAt: "30 Nov 2022",
          title: "Should Fixing Corporate Take 100 Steps.",
          excerpt: "We have always known the power of digital we've.",
          body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
          categories: ["UX Design", "Company"],
          tags: ["Minimal", "Company", "Development", "Design"],
        },
        {
          id: "8",
          img: require(`@/assets/images/blog/blog-03.jpg`),
          author: "John Dou",
          authorId: "3",
          view: "70 views",
          publishedAt: "12 Oct 2022",
          title: "The Next 100 Things To Immediately Do About.",
          excerpt: "We have always known the power of digital we've.",
          body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
          categories: ["Development", "UX Design", "Business", "Company"],
          tags: ["App Landing", "Startup", "Development", "Design"],
        },
      ],
      categories: "",
      tags: "",
      AppFunctions,
    };
  },
  methods: {
    blogCategories() {
      let cats = this.posts.map((item) => {
          return item.categories;
        }),
        singleCatArray = AppFunctions.flatDeep(cats),
        categories = [];

      singleCatArray.forEach((cat) => {
        const obj = {
          title: cat.trim(),
          slug: AppFunctions.slugify(cat),
          count: 1,
        };
        const objIndex = AppFunctions.containsObject(obj, categories);
        if (objIndex !== -1) {
          const prevCount = categories[objIndex].count;
          categories[objIndex] = {
            title: cat.trim(),
            slug: AppFunctions.slugify(cat),
            count: prevCount + 1,
          };
        } else {
          categories.push(obj);
        }
      });

      this.categories = categories;
    },
    blogTags() {
      let tags = this.posts.map((item) => {
          return item.tags;
        }),
        singleTagArray = AppFunctions.flatDeep(tags),
        tagsData = [];

      singleTagArray.forEach((tag) => {
        const obj = {
            title: tag.trim(),
            slug: AppFunctions.slugify(tag),
          },
          objIndex = AppFunctions.containsObject(obj, tagsData);

        if (objIndex !== -1) {
          tagsData[objIndex] = {
            title: tag.trim(),
            slug: AppFunctions.slugify(tag),
          };
        } else {
          tagsData.push(obj);
        }
      });

      this.tags = tagsData;
    },
  },
  created() {
    this.blogCategories();
    this.blogTags();
  },
};
