<template>
    <Layout>
        <Breadcrumb title="案例展示" current-url="案例列表" parentUrl="首页" />

        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div
                                class="col-lg-6 mt--30 blog-item"
                                data-aos="slide-up"
                                data-aos-duration="800"
                                :data-aos-delay="100 + index"
                                v-for="(blog, index) in data.rows"
                                :key="index">
                                <BlogPost :blog="blog" blog-post-style="list" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Icon from "@/components/icon/Icon";
import BlogPost from "@/components/blog/BlogPost";
import Layout from "@/components/common/Layout";
import Breadcrumb from "@/components/elements/breadcrumb/Breadcrumb";
import { getDetailList } from "@/api/detail";

export default {
    name: "ProjectListPage",
    components: { Icon, BlogPost, Layout, Breadcrumb },
    data() {
        return {
            data: {
                rows: [],
            },
            initData: {
                page: 1,
                limit: 6,
            },
        };
    },
    methods: {
        async fetchData() {
            const resp = await getDetailList(this.initData);
            if (resp.data.data.total > this.data.rows.length) {
                this.data.rows = [...this.data.rows, ...resp.data.data.rows];
                this.initData.page += 1;
            } else {
                // 如果没有更多数据，停止观察
                console.log("结束");
                if (this.observer) {
                    this.observer.disconnect();
                }
                return;
            }

            this.$nextTick(() => {
                this.createObserver();
            });
        },

        createObserver() {
            if (this.observer) {
                this.observer.disconnect(); // 断开旧的观察者实例
            }
            const observerOptions = {
                root: null,
                rootMargin: "20px",
                threshold: 0.1,
            };
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const target = entry.target;
                        this.fetchData();
                        this.observer.unobserve(target);
                    }
                });
            }, observerOptions);

            const blogs = document.querySelectorAll(".blog-item");
            const lastItem = blogs[blogs.length - 1]; // 获取最后一个元素
            if (lastItem) {
                this.observer.observe(lastItem);
            }
        },
    },

    created() {
        this.fetchData();
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
};
</script>

<style lang="scss" scoped>
.observer-element {
    width: 50px;
    height: 50px;
    background: red;
}
</style>
